/*
 * @Author: Saving
 * @Date: 2024-03-31 20:39:10
 * @LastEditors: Saving
 * @LastEditTime: 2024-03-31 20:40:12
 * @Description: 请输入
 */
import { BASE_URL } from "@/const";
import request from "@/utils/request";

/**
 * 生成报项统计表
 */
export function generateTable() {
  return request({
    url: `${BASE_URL}/api/statistics/generateTable`,
    method: "post"
  });
}
