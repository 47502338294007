<template>
  <div class="step-6">
    <div class="unsubmitted" v-if="loading">
      <p class="loadingCss">加载中...</p>
    </div>
    <div class="unsubmitted" v-if="!loading && status === 0">
      <el-button style="width: 200px;" @click="prevStep">上一步</el-button>
      <el-button style="width: 200px;" type="primary" @click="handleConfirm"
        >提交</el-button
      >
    </div>
    <div class="pending" v-else-if="status === 1">
      <div class="title">
        <i class="pending-success el-icon-success"></i>提交成功
      </div>
      <div class="info">管理员审核中，请耐心等待。</div>
      <el-button type="danger" style="margin-top:20px" @click="cancelSubmit"
        >取消提交</el-button
      >
    </div>
    <div class="pass" v-else-if="status === 2">
      <div class="title">已经通过审核</div>
      <div style="text-align: center;">
        <el-button
          type="primary"
          :loading="download"
          @click="downReport"
          >下载汇总表</el-button
        >
        <!-- <div class="pass-tips">
          <p>请于4 月 20 日前,将以下报名资料邮寄至组委地址</p>
          <p>
            （地址：三水区西南街道锦江路 7 号一座 202, 收件人：刘浩荣,
            收件电话：15602806882）
          </p>
          <div style="text-align: left">
            需要邮寄的材料:
            <p>
              1、请附下载打印的<span>报名汇总表</span>
              <span
                style="cursor: pointer; color: blue; margin-left: 10px"
                @click="downloadFile"
                >下载</span
              >
            </p>
            <p>2、<span>汇款回执</span>或凭据复印件</p>
            <p>3、运动员<span>自愿参赛责任书</span></p>
            <p>4、<span>二代身份证</span>或户口簿复印件</p>
            <p>
              5、<span>人身意外保险复印件</span>（受保期限含报到、离会交通往返途中，验原件）
            </p>
            <p>
              6、<span>医院体检健康证明</span>（经县级以上（含县级）医务部门检查的健康证明）
            </p>
          </div>
        </div> -->
      </div>
    </div>
    <div class="nopass" v-else-if="status === 3">
      <div class="title">
        <i class="pending-error el-icon-error"></i>审核未通过
      </div>
      <div class="info">未通过原因：{{ reason }}</div>
      <div class="resubmit">
        <el-button style="width: 150px" type="primary" @click="handleReSubmit"
          >重新报名</el-button
        >
      </div>
    </div>
    <div class="report-wrap" ref="report" v-if="!loading">
      <ul class="report-team-info">
        <li>责任书：{{ responsibility.length > 0 ? "" : "无" }}</li>
      </ul>
      <div
        class="image-preview"
        style="width: 100%; max-width: 1000px; margin: 0 auto;"
        v-if="responsibility.length > 0"
      >
        <el-image
          v-for="(item, index) in responsibility"
          :key="'responsibility' + index"
          style="width: 100px; height: 100px; margin-right: 10px;"
          :src="item"
          :preview-src-list="responsibility"
          fit="cover "
        ></el-image>
      </div>

      <ul class="report-team-info">
        <li>汇款证明：{{ prove.length > 0 ? "" : "无" }}</li>
      </ul>
      <div
        v-if="prove.length > 0"
        class="image-preview"
        style="width: 100%; max-width: 1000px; margin: 0 auto;"
      >
        <el-image
          v-for="(item, index) in prove"
          :key="index"
          style="width: 100px; height: 100px; margin-right: 10px;"
          :src="item"
          :preview-src-list="prove"
          fit="cover"
        ></el-image>
      </div>

      <ul class="report-team-info info">
        <li><span>队伍名称：</span>{{ team.teamName }}</li>
        <li><span>领队：</span>{{ team.leaderName }}</li>
        <li><span>领队联系电话：</span>{{ team.leaderPhone }}</li>
        <div class="clear"></div>
      </ul>
      <ul class="report-team-info info">
        <li v-if="team.coachName">
          <span>套路项目教练：</span>{{ team.coachName }}
        </li>
        <li v-if="team.coachPhone">
          <span>教练手机：</span>{{ team.coachPhone }}
        </li>

        <li v-if="team.coachName2">
          <span>推手项目教练：</span>{{ team.coachName2 }}
        </li>
        <li v-if="team.coachPhone2">
          <span>教练手机：</span>{{ team.coachPhone2 }}
        </li>
        <div class="clear"></div>
      </ul>

      <div class="report-item">
        <div class="report-item__header">个人项目</div>
        <el-table
          class="report-item__body"
          :data="person"
          max-height="400px"
          border
          fit
          style="width: 100%;"
        >
          <el-table-column
            type="index"
            label="序号"
            width="50"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="姓名"
            width="100"
          ></el-table-column>
          <el-table-column
            prop="cardId"
            label="证件号码"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="birth"
            label="出生年月"
            width="110"
          ></el-table-column>
          <el-table-column prop="sex" label="性别" width="60"></el-table-column>
          <el-table-column
            prop="group"
            label="组别"
            width="115"
          ></el-table-column>
          <el-table-column
            prop="itemName"
            label="项目名称"
            min-width="280"
          ></el-table-column>
          <el-table-column prop="cost" label="费用"></el-table-column>
        </el-table>
        <div class="cost-box">合计：{{ personalCost }} 元</div>
      </div>

      <div class="report-item">
        <div class="report-item__header">对练项目</div>
        <el-table
          class="report-item__body"
          :data="duel"
          max-height="400px"
          border
          fit
          style="width: 100%;"
        >
          <el-table-column
            prop="index"
            label="序号"
            width="50"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="姓名"
            width="100"
          ></el-table-column>
          <el-table-column
            prop="cardId"
            label="证件号码"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="birth"
            label="出生年月"
            width="110"
          ></el-table-column>
          <el-table-column prop="sex" label="性别" width="60"></el-table-column>
          <!-- <el-table-column
            prop="group"
            label="所在组别"
            width="120"
          ></el-table-column> -->
          <el-table-column
            prop="itemName"
            label="项目名称"
            min-width="280"
          ></el-table-column>
          <el-table-column prop="cost" label="费用"></el-table-column>
        </el-table>
        <div class="cost-box">合计：{{ duelCost }} 元</div>
      </div>

      <div class="report-item">
        <div class="report-item__header">集体项目</div>
        <el-table
          class="report-item__body"
          :data="collective"
          max-height="400px"
          border
          fit
          style="width: 100%;"
        >
          <el-table-column
            prop="index"
            label="序号"
            width="55"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="姓名"
            width="100"
          ></el-table-column>
          <el-table-column
            prop="cardId"
            label="证件号码"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="birth"
            label="出生年月"
            width="110"
          ></el-table-column>
          <el-table-column prop="sex" label="性别" width="60"></el-table-column>
          <el-table-column
            prop="teamGroup"
            label="组别"
            width="110"
          ></el-table-column>
          <el-table-column
            prop="itemName"
            label="项目名称"
            min-width="280"
          ></el-table-column>
          <el-table-column prop="cost" label="费用">100</el-table-column>
        </el-table>
        <div class="cost-box">合计：{{ collectiveCost }} 元</div>
      </div>

      <div class="report-item">
        <div class="report-item__header">太极推手项目</div>
        <el-table
          class="report-item__body"
          :data="kickboxing"
          max-height="400px"
          border
          fit
          style="width: 100%;"
        >
          <el-table-column
            type="index"
            label="序号"
            width="60"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="姓名"
            width="90"
          ></el-table-column>
          <el-table-column
            prop="cardId"
            label="证件号码"
            width="180"
          ></el-table-column>
          <el-table-column
            prop="birth"
            label="出生年月"
            width="110"
          ></el-table-column>
          <el-table-column prop="sex" label="性别" width="55"></el-table-column>
          <!-- <el-table-column
            prop="height"
            label="身高"
            width="60"
          ></el-table-column> -->
          <el-table-column prop="weight" label="体重级别"></el-table-column>
          <el-table-column prop="tuishouGroup" label="组别"></el-table-column>
          <!-- <el-table-column
            prop="learningCycle"
            label="学习周期"
            width="80"
          ></el-table-column> -->
          <el-table-column prop="tuishouType" label="类型" width="80"></el-table-column>
          <el-table-column prop="cost" label="费用"></el-table-column>
        </el-table>
        <div class="cost-box">合计：{{ kickboxingCost }} 元</div>
      </div>

      <div class="report-item">
        <el-table
          class="report-item__body"
          :data="aggregateCost"
          border
          fit
          style="width: 100%;"
        >
          <el-table-column
            prop="item"
            label="项目"
            width="100"
          ></el-table-column>
          <el-table-column
            prop="personal"
            label="个人项目"
            min-width="100"
          ></el-table-column>
          <!-- <el-table-column prop="duel" label="对练项目" min-width="100"></el-table-column> -->
          <el-table-column
            prop="collective"
            label="集体项目"
            min-width="100"
          ></el-table-column>
          <!-- <el-table-column prop="people" label="运动员人数" min-width="100"></el-table-column> -->
          <el-table-column
            prop="kickboxing"
            label="太极推手项目"
            min-width="100"
          ></el-table-column>
          <el-table-column
            prop="cost"
            label="合计（元）"
            width="385"
          ></el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { generateTable } from "@/api/Statistics.js";
import {
  getAll,
  formSubmit,
  formReSubmit,
  getAllAdmin,
  formSubmitAdmin,
  outputInfo
} from "@/api/index.js";
import { BASE_URL } from "@/const";
export default {
  name: "Step6",
  components: {},
  props: {
    value: {
      type: Object
    }
  },
  data() {
    return {
      download: false, // 下载加载动画
      loading: false,
      team: {},
      personT: [],
      duelT: [],
      collectiveT: [],
      kickboxingT: [],
      responsibility: [],
      prove: [],
      reason: "",
      data: {
        // 导出用数组
        team: {},
        person: [],
        duel: [],
        collective: [],
        responsibility: [],
        prove: [],
        reason: ""
      },
      peopleSum: 0 // 运动员人数
    };
  },
  computed: {
    status: {
      get() {
        // return 2
        return this.$store.state.project.status;
      },
      set(val) {
        this.$store.commit("SET_STATUS", val);
      }
    },
    // responsibility: {
    //   get () {
    //     return this.$store.state.project.responsibility
    //   },
    //   set (val) {
    //     this.$store.commit('SET_RESPONSIBILITY', val)
    //   }
    // },
    // remittance: {
    //   get () {
    //     return this.$store.state.project.remittance
    //   },
    //   set (val) {
    //     this.$store.commit('SET_REMITTANCE', val)
    //   }
    // },
    person() {
      let arr = [];
      this.personT.forEach(item => {
        if (item.boxing) {
          arr.push({
            ...item,
            itemName: `${item.boxing}${item.boxingRoutine ? "-" : ""}${
              item.boxingRoutine
            }`,
            cost: 120
          });
        }
        if (item.instrument) {
          arr.push({
            ...item,
            itemName: `${item.instrument}${item.instrumentRoutine ? "-" : ""}${
              item.instrumentRoutine
            }`,
            cost: 120
          });
        }
      });
      return arr;
    },
    duel() {
      // return this.$store.state.project.duel
      let arr = [];
      this.duelT.forEach((item, index) => {
        item.contestants?.forEach((contestant, contestantsIndex) => {
          arr.push({
            index: `${index + 1}-${contestantsIndex + 1}`,
            ...contestant,
            itemType: item.itemType,
            itemRoutine: item.itemRoutine,
            itemName: `${item.itemRoutine}`,
            cost: 120
          });
        });
      });
      return arr;
    },
    // 集体
    collective() {
      let arr = [];
      this.collectiveT.forEach((item, index) => {
        item.contestants?.forEach((contestant, contestantsIndex) => {
          arr.push({
            index: `${index + 1}-${contestantsIndex + 1}`,
            ...contestant,
            itemType: item.itemType,
            itemRoutine: item.itemRoutine,
            itemName:
              item.item + (`${item.itemRoutine}` ? `-${item.itemRoutine}` : ""),
            cost: 60
          });
        });
      });
      return arr;
    },

    kickboxing() {
      // return this.$store.state.project.kickboxing
      let arr = [];
      this.kickboxingT.forEach(item => {
        arr.push({
          ...item,
          cost: 120
        });
      });
      return arr;
    },

    personalCost() {
      let cost = 0;
      this.person.forEach(element => {
        cost += 120;
      });
      return cost;
    },
    duelCost() {
      let cost = 0;
      this.duel.forEach(element => {
        cost += 120;
      });
      return cost;
    },
    collectiveCost() {
      let cost = 0;
      this.collective.forEach(element => {
        cost += 100;
      });
      return cost;
    },
    kickboxingCost() {
      let cost = 0;
      this.kickboxing.forEach(element => {
        cost += 120;
      });
      return cost;
    },
    // 汇总费用
    aggregateCost() {
      return [
        {
          item: "费用",
          personal: this.personalCost,
          duel: this.duelCost,
          collective: this.collectiveCost,
          kickboxing: this.kickboxingCost,
          //cost: this.peopleSum * 20, // 按人数收费
          cost:
            this.personalCost +
            this.duelCost +
            this.collectiveCost +
            this.kickboxingCost, // 按项目收费
          people: this.peopleSum
        }
      ];
    }
  },
  watch: {},
  created() {
    this.loading = true;
    if (this.$store.state.user.authority === 0) {
      // 用户
      getAll()
        .then(res => {
          this.$store.commit("SET_STATUS", res.data.status);
          this.peopleSum = res.data.peopleSum;
          this.team = res.data.team;
          this.personT = res.data.person;
          this.duelT = res.data.duel;
          this.collectiveT = res.data.collective;
          this.kickboxingT = res.data.kickboxing;
          // this.prove = res.data.prove
          let responsibility =
            res.data.responsibility && res.data.responsibility.length > 0
              ? res.data.responsibility.split(",")
              : [];
          let responsibilityarr = [];
          responsibility.forEach(item => {
            responsibilityarr.push(item);
          });
          this.responsibility = responsibilityarr;
          let prove =
            res.data.prove.length > 0 ? res.data.prove.split(",") : [];
          let provearr = [];
          prove.forEach(item => {
            provearr.push(item);
          });
          this.prove = provearr;
          this.reason =
            res.data.reason && res.data.reason !== ""
              ? res.data.reason
              : "未填写打回原因";
          this.loading = false;
        })
        .catch(err => {
          console.error(err);
        });
    } else {
      // 管理员
      getAllAdmin()
        .then(res => {
          this.$store.commit("SET_STATUS", res.data.status);
          this.peopleSum = res.data.peopleSum;
          this.team = res.data.team;
          this.personT = res.data.person;
          this.duelT = res.data.duel;
          this.collectiveT = res.data.collective;
          this.kickboxingT = res.data.kickboxing;
          // this.responsibility = res.data.responsibility
          // this.prove = res.data.prove
          let responsibility =
            res.data.responsibility && res.data.responsibility.length > 0
              ? res.data.responsibility.split(",")
              : [];
          let responsibilityarr = [];
          responsibility.forEach(item => {
            responsibilityarr.push(item);
          });
          this.responsibility = responsibilityarr;
          let prove =
            res.data.prove.length > 0 ? res.data.prove.split(",") : [];
          let provearr = [];
          prove.forEach(item => {
            provearr.push(item);
          });
          this.prove = provearr;
        })
        .catch(err => {
          console.error(err);
        });
    }
  },
  mounted() {},
  methods: {
    prevStep() {
      this.$store.dispatch("STEP_PREV", {
        router: this.$router,
        route: this.$route
      });
    },
    handleConfirm() {
      this.$confirm("提交后无法修改内容", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(() => {
        if (this.$store.state.user.authority === 0) {
          // 用户
          // 生成报告
          generateTable().catch(err => {
            this.$message({
              type: "error",
              message: err
            });
          });
          formSubmit()
            .then(res => {
              if (res.data.code === 200) {
                this.$store.commit("SET_STATUS", res.data.status);
              }
            })
            .catch(err => {
              this.$message({
                type: "error",
                message: err
              });
            });
        } else {
          // 管理员
          formSubmitAdmin()
            .then(res => {
              if (res.data.code === 200) {
                this.$store.commit("SET_STATUS", res.data.status);
              }
            })
            .catch(err => {
              this.$message({
                type: "error",
                message: err
              });
            });
        }
      });
    },
    // 取消提交
    cancelSubmit() {
      this.$confirm("取消后可修改内容", "确认取消提交吗", {}).then(() => {
        formReSubmit()
          .then(res => {
            if (res.data.code === 200) {
              this.$alert("取消提交成功，点击确定跳转至首页。", "系统消息", {
                callback: action => {
                  this.$store.commit("SET_STATUS", res.data.status);
                  this.$store.commit("SET_STEP", 0);
                  this.$store.dispatch("STEP_GO", {
                    router: this.$router,
                    route: this.$route
                  });
                }
              });
            }
          })
          .catch(err => {
            this.$message({
              type: "error",
              message: err
            });
          });
      });
    },
    // 下载汇总表
    downReport() {
      // html2canvas(this.$refs.report).then(canvas => {
      //   // 转成图片，生成图片地址
      //   const imgUrl = canvas.toDataURL('image/png');
      //   // 创建隐藏的可下载链接
      //   let eleLink = document.createElement('a');
      //   eleLink.href = imgUrl; // 转换后的图片地址
      //   eleLink.download = '汇总表';
      //   // 触发点击
      //   document.body.appendChild(eleLink);
      //   eleLink.click();
      //   // 然后移除
      //   document.body.removeChild(eleLink)
      // }
      this.data.team = this.team;
      this.data.person = this.personT;
      this.data.duel = this.duelT;
      this.data.collective = this.collectiveT;
      this.data.kickboxing = this.kickboxingT;

      this.download = true;
      outputInfo(this.data)
        .then(res => {
          if (res.status === 200) {
            this.download = false;
            let blob = new Blob([res.data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
            }); //application/vnd.openxmlformats-officedocument.spreadsheetml.sheet这里表示xlsx类型
            let downloadElement = document.createElement("a");
            let href = window.URL.createObjectURL(blob); //创建下载的链接
            downloadElement.href = href;
            downloadElement.download = `${this.data.team.teamName}报名详情.xlsx`; //下载后文件名：队伍名+报名详情
            document.body.appendChild(downloadElement);
            downloadElement.click(); //点击下载
            document.body.removeChild(downloadElement); //下载完成移除元素
            window.URL.revokeObjectURL(href); //释放掉blob对象
          } else {
            this.download = false;
          }
        })
        .catch(err => {
          this.download = false;
          console.error(err);
        });
    },
    handleReSubmit() {
      this.$confirm("是否已悉知打回原因", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(() => {
        formReSubmit()
          .then(res => {
            if (res.data.code === 200) {
              this.$store.commit("SET_STATUS", res.data.status);
              this.$store.commit("SET_STEP", 0);
              this.$store.dispatch("STEP_GO", {
                router: this.$router,
                route: this.$route
              });
            }
          })
          .catch(err => {
            this.$message({
              type: "error",
              message: err
            });
          });
      });
    },
    downloadFile() {
      // https://contests.lambdass.com/ss_taiji/public/printVersion/佛山伟志拳馆-报名汇总表.pdf
      window.open(
        `${BASE_URL}/printVersion/${this.team.teamName}-报名汇总表.pdf`,
        "_blank"
      );
    }
  }
};
</script>

<style scoped lang="scss">
.unsubmitted {
  text-align: center;
  padding: 60px 0 0;
}
.pending {
  text-align: center;
  margin-top: 50px;
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    line-height: 1;
  }
  .info {
    color: #969696;
    margin-top: 10px;
  }
  .pending-success {
    color: green;
    font-size: 46px;
    margin-right: 10px;
  }
  p {
    text-align: center;
    color: #969696;
  }
}

// .report-team-info {
//   margin-top: 15px;
// }
.image-preview {
  padding: 20px 0 20px;
}
.pass {
  padding: 20px 0 30px;
  .title {
    color: green;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
  }
  .pass-tips {
    width: 320px;
    margin: 0 auto;
    span {
      font-weight: bold;
    }
  }
}
.nopass {
  padding: 20px 0 30px;
  .title {
    color: red;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  .info {
    color: #969696;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
  }
  .resubmit {
    text-align: center;
  }
}
.report-wrap {
  width: 100%;
  padding: 45px 0 45px;
  max-width: 1000px;
  margin: 0 auto;
}

.clear {
  clear: both;
}

.report-item {
  margin: 0px auto 0;
}

.teamBox {
  min-height: 100px;
  border: 1px solid #bab9b9;
  margin-bottom: 20px;
}

.info {
  padding: 15px 0;
  // border-top: 1px solid;
}

.info li {
  float: left;
  margin: 5px 0 5px 15px;
  color: #409eff;
}

.info span {
  color: #000;
}

.loadingCss {
  font-size: 24px;
  letter-spacing: 8px;
  font-weight: bold;
  margin: 20px;
}

.flex {
  display: flex;
}
.common-table_mb {
  display: none;
}
.common-table_mb .img_box {
  margin-right: 8px;
}
.common-table_mb img {
  width: 20px;
  height: auto;
}
.common-table_mb .content_box img {
  width: 18px;
  height: auto;
  float: left;
  margin: 5px 0;
}
.common-table_mb .content_box span {
  line-height: 26px;
  display: inline-block;
}

.common-table_mb .card_box {
  margin-bottom: 25px;
}
.common-table_mb .card_box:last-child {
  margin-bottom: 5px;
}
.content_box > p:first-child {
  font-weight: bold;
}
.grey {
  color: #999999;
}
.padding_top-xm {
  padding-top: 8px;
}
.group_box {
  font-weight: bold;
}
.right_box {
  text-align: right;
}
.common-table_mb ul li {
  display: flex;
}
@media screen and (max-width: 991px) {
  .common-table_pc {
    display: none;
  }
  .common-table_mb {
    display: block;
  }
  .report-wrap {
    padding: 45px 0;
  }
}
@media screen and (max-width: 470px) {
  .unsubmitted .el-button + .el-button {
    margin-left: 0;
    margin-top: 10px;
  }
}
</style>
